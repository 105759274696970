import type { LayoutRecord } from "~~/server/graphql/datocms/generated";

export async function useLayout() {
  const nuxtApp = useNuxtApp();

  const { data } = await useFetch<LayoutRecord>("/api/cms/layout", {
    getCachedData: key => nuxtApp.payload.data[key] ?? nuxtApp.static.data[key],
  });

  return data;
}
