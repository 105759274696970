<script setup lang="ts">
import type { LinkColumnRecord } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ linkColumns: LinkColumnRecord[] }>();

const localePath = useLocalePath();

const footerLinkColumns = computed(() => {
  return props.linkColumns.map(menuLink => getLinkForType(menuLink, localePath));
});
</script>

<template>
  <div class="gap-xl sm:gap-5xl flex flex-col sm:flex-row">
    <!-- column -->
    <div v-for="column in footerLinkColumns" :key="column.id" class="gap-xl sm:gap-2xl flex flex-col">
      <!-- column set -->
      <ul v-for="columnSet in column.subLinks?.at(0)" :key="columnSet.id" class="gap-2xl">
        <li>
          <h3 class="mb-lg text-sm font-medium uppercase text-[--color-footer-main-text-accent]">
            {{ columnSet.label }}
          </h3>
          <!-- set link -->
          <ul class="gap-sm flex flex-col">
            <li v-for="link in columnSet.subLinks?.at(0)" :key="link.id" class="">
              <LinkCustom class="hover:!text-[--color-footer-main-text]" :link="link" />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
