<script setup lang="ts">
const layout = await useLayout();
</script>

<template>
  <footer v-if="layout" class="flex flex-col">
    <FooterMain
      :link-columns="layout.footerLinkColumns"
      :tagline="layout.taglineHtml"
      :newsletter="layout.newsletterSubscription.newsletter"
    />
    <FooterClosing
      :subtitle="layout.footerSubtitle"
      :legal-links="layout.footerLegalLinks"
      :social-media-links="layout.socialMedia"
    />
  </footer>
</template>
