<script lang="ts" setup>
import type { LinkItem } from "~~/models/links";

defineProps<{ link: LinkItem }>();

const mobileMenu = useMobileMenu();
</script>

<template>
  <NuxtLink
    v-if="link.href && link.click"
    :to="link.href"
    v-bind="$attrs"
    class="hover:text-primary cursor-pointer outline-0"
    @click.prevent="link.click as Function"
  >
    <i v-if="link.iconName" :class="`ph-${link.iconName}`" />
    {{ link.label }}
    <slot name="content-after" />
  </NuxtLink>

  <NuxtLink
    v-else-if="link.href"
    :to="link.href"
    :target="link.target"
    v-bind="$attrs"
    class="hover:text-primary outline-0"
    @click="mobileMenu.isOpen = false"
  >
    <i v-if="link.iconName" :class="`ph-${link.iconName}`" />
    {{ link.label }}
    <slot name="content-after" />
  </NuxtLink>

  <span v-else v-bind="$attrs">{{ link.label }}</span>
</template>

<style scoped>

</style>
