<script lang="ts" setup>
import type { LayoutModelMenuField } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ menuLinks: Array<LayoutModelMenuField> }>();

const localePath = useLocalePath();
const mobileMenu = useMobileMenu();

const links = computed(() => {
  const links = props.menuLinks.map(menuLink => getLinkForType(menuLink, localePath));

  for (const link of links) {
    link.click = async () => {
      await navigateTo(link.href);
      mobileMenu.value.isOpen = false;
    };
  }

  return links;
});
</script>

<template>
  <nav>
    <ul class="gap-md flex flex-col">
      <li v-for="link in links" :key="link?.id">
        <LinkCustom v-if="!link!.subLinks" class="py-sm block px-0" :link="link" />
        <HeaderLinkWithPanel v-if="link!.subLinks" :link="link" />
      </li>
    </ul>
  </nav>
</template>
