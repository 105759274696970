<script lang="ts" setup>
import type { LinkItem } from "~~/models/links";

defineProps<{ link: LinkItem }>();

const route = useRoute();

const isOpen = ref(false);

watch(() => route.path, () => isOpen.value = false);
</script>s

<template>
  <UPopover
    v-model:open="isOpen"
    mode="click"
    :close-delay="200"
    :ui="{
      wrapper: 'static',
      container: `!translate-x-0 md:!top-6xl md:!mt-[1px] !p-0 w-full h-full overflow-auto`,
      base: 'max-w-[1120px] xl:max-w-[1280px] mx-auto pl-2xl md:px-4xl xl:px-[80px] md:pt-[55px] pb-xl xl:pb-[55px] pointer-events-auto',
    }"
    :popper="{
      strategy: 'absolute',
    }"
  >
    <span class="gap-x-2xs lg:gap-x-xs py-sm flex items-center px-0 md:py-0">
      <span :class="{ 'font-bold': isOpen }" class="underline-offset-[3px] hover:underline">{{ link.label }}</span>
      <UIcon name="i-ph-caret-right" class="transition-transform duration-300 md:hidden" />
      <UIcon name="i-ph-caret-down" :class="{ '-rotate-180': isOpen }" class="hidden transition-transform duration-300 md:block" />
    </span>
    <template #panel>
      <span class="p-xs -ml-xl my-xl inline-flex md:hidden" role="button" @click="isOpen = false">
        <UIcon name="i-ph-arrow-left" class="size-lg" />
      </span>
      <div class="flex h-full w-fit flex-col md:mx-auto xl:w-full xl:flex-row">
        <div class="flex flex-1 flex-col">
          <div class="gap-md md:gap-5xl xl:gap-md grid md:grid-cols-3">
            <!-- column -->
            <div v-for="(subLinksColumn, index) of link!.subLinks" :key="index">
              <!-- sub links list -->
              <ul v-for="subLink of subLinksColumn" :key="subLink.id" class="mb-md md:mb-xl flex flex-col last:mb-0">
                <li>
                  <span class="inline-flex items-baseline">
                    <Bullet color="accent" />
                    <LinkCustom class="font-bold" :link="subLink" />
                  </span>

                  <!-- sub links -->
                  <ul v-if="subLink!.subLinks" class="mt-lg md:mt-xl gap-lg md:gap-xl flex flex-col">
                    <li v-for="subSubLink of subLink!.subLinks.at(0)" :key="subSubLink.id">
                      <LinkCustom :link="subSubLink" class="ml-sm md:ml-lg" />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <UButton
            v-if="link.callToAction"
            :to="link.callToAction!.href!"
            :target="link.callToAction!.target!"
            class="mt-2xl ms-[-25px] w-fit"
            icon="i-ph-arrow-right"
            trailing
          >
            {{ link!.callToAction.label }}
          </UButton>
        </div>

        <HeaderSidePanel
          v-if="link.sidePanel"
          :data="link.sidePanel"
          class="pt-xl md:pt-2xl mt-2xl md:mt-3xl ms-[-25px] border-t ps-0 xl:ms-0 xl:mt-0 xl:border-l xl:border-t-0 xl:ps-[4.5rem] xl:pt-0"
        />
      </div>
    </template>
  </UPopover>
</template>
