<script setup lang="ts">
import type { NewsletterRecord } from "~~/server/graphql/datocms/generated";

defineProps<{ newsletter: NewsletterRecord }>();

const { locale } = useI18n();
const emailInput = ref("");
</script>

<template>
  <div v-if="newsletter.remoteFormUrl" class="sm:p-lg sm:bg-[--color-footer-newsletter-bg]">
    <div class="mb-xs text-lg font-bold">
      {{ newsletter.title }}
    </div>
    <div v-if="newsletter.optText" class="mb-lg" v-html="newsletter.optText" />
    <form
      id="newsletter-subscription-form"
      :action="newsletter.remoteFormUrl"
      method="post"
      role="form"
      class="gap-lg flex flex-col sm:flex-row"
    >
      <input type="hidden" name="LANGUAGE" :value="locale">

      <UFormGroup name="email" class="flex-1 sm:max-w-[357px]">
        <UInput
          id="MERGE0"
          v-model="emailInput"
          name="MERGE0"
          required
          type="email" autocomplete="email" inputmode="email" spellcheck="false"
          data-1p-ignore
          :placeholder="newsletter.inputPlaceholder ?? ''"
        />
      </UFormGroup>

      <UButton type="submit" color="white" variant="ghost" icon="i-ph-arrow-right" trailing class="border-b-gradient-white justify-center">
        {{ newsletter.buttonText }}
      </UButton>
    </form>
  </div>
</template>

<style>
:root {
  .sf-hangcha {
    --color-footer-newsletter-bg: rgb(var(--color-white) / 0.13);
  }
}
</style>
