<script setup lang="ts">
import type { LinkColumnRecord, NewsletterRecord } from "~~/server/graphql/datocms/generated";

defineProps<{
  linkColumns: LinkColumnRecord[];
  tagline: string;
  newsletter: NewsletterRecord;
}>();

const store = await useStore();
</script>

<template>
  <div class="py-2xl md:py-5xl bg-[--color-footer-main-bg] text-[--color-footer-main-text]">
    <UContainer class="gap-xl grid w-fit grid-cols-1 sm:gap-[72px] lg:w-auto lg:grid-cols-[1fr_minmax(625px,1fr)]">
      <h2 class="hidden lg:block">
        <img :src="`/${store!.code}/logo-footer.svg`" :alt="`${store!.name}`" class="w-[178px]">
      </h2>
      <FooterLinks :link-columns="linkColumns" />
      <div class="leading-p-150 hidden text-3xl uppercase lg:block [&_em]:text-[--color-footer-main-text-accent]" v-html="tagline" />
      <FooterNewsletter :newsletter="newsletter" />
    </UContainer>
  </div>
</template>

<style>
:root {
  .sf-hangcha {
    --color-footer-main-text: rgb(var(--color-white));
    --color-footer-main-bg: rgb(var(--color-black));
    --color-footer-main-text-accent: rgb(var(--color-primary-300));
  }
}
</style>
