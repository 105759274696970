<script lang="ts" setup>
import type { LayoutModelMenuField } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ menuLinks: Array<LayoutModelMenuField> }>();

const localePath = useLocalePath();
const mobileMenu = useMobileMenu();

const links = computed(() => {
  const links = props.menuLinks.map(menuLink => getLinkForType(menuLink, localePath));

  for (const link of links) {
    link.click = async () => {
      await navigateTo(link.href);
      mobileMenu.value.isOpen = false;
    };
  }

  return links;
});
</script>

<template>
  <nav class="ms-[calc(5px+6%)] w-full lg:ms-auto lg:w-auto">
    <ul class="flex w-full flex-wrap items-center justify-between lg:gap-x-14">
      <li v-for="link in links" :key="link?.id">
        <LinkCustom v-if="!link!.subLinks" class="block" :link="link" />
        <HeaderLinkWithPanel v-if="link!.subLinks" :link="link" />
      </li>
      <li class="hidden md:list-item">
        <HeaderLocaleSwitcher />
      </li>
    </ul>
  </nav>
</template>
