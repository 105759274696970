<script setup lang="ts">
const layout = await useLayout();
const store = await useStore();
</script>

<template>
  <header v-if="layout && store" class="shadow-header bg-[--color-header-bg] text-[--color-header-text]">
    <HeaderMobileBase :menu-links="layout.menu" :store="store" class="md:hidden" />
    <HeaderStandardBase :menu-links="layout.menu" :store="store" class="hidden md:block" />
  </header>
</template>

<style>
:root {
  .sf-hangcha {
    --color-header-text: rgb(var(--color-primary));
    --color-header-bg: rgb(var(--color-white));
  }
}
</style>
